/**
 * Get length, in inches, of dick recieved (or given)
 * 
 * @param {Number} bodyCount Number of sexual partners
 * @param {Number} asize Average size, in inches, of sexual partners
 * @param {Number} tps Thrusts per session
 * @param {Number} spp Sessions per partner
 * @returns Length, in inches, of dick recieved (or given)
 */
exports.getLength = (bodyCount, asize, tps, spp) => bodyCount * ((spp * tps) * asize);

/**
 * Get the amount of semen, in millileters, exchanged
 * 
 * @param {Number} bodyCount Number of sexual partners
 * @param {Number} spp Sessions per partner
 * @param {Number} averageAmount Optionally change the average amount from it's default of 3.7ml
 * @returns Amount of semen, in millileters, exchanged
 */
exports.getAmount = (bodyCount, spp, averageAmount = 3.7) => (bodyCount + spp) * averageAmount;

/**
 * Get the amount of data, in gigabytes, echanged via semen
 * 
 * @param {Number} bodyCount Number of sexual partners
 * @param {Number} spp Sessions per partner
 * @param {Number} averageAmount Optionally change the average amount from it's default of 15,875 GB
 * @returns Amount of data, in gigabytes, echanged via semen
 */
exports.getData = (bodyCount, spp, averageAmount = 15875) => (bodyCount + spp) * averageAmount;
import {
  SimpleGrid,

  Stat,
  StatLabel,
  StatNumber

} from '@chakra-ui/react';

function StatsContainer({ stats }) {
  return (
    <SimpleGrid columns={[ 2, 3, 3 ]} spacing={10} padding={1}>
      {stats.map((stat) => (
        <Stat key={stat.name}>
          <StatLabel>{stat.name}</StatLabel>
          <StatNumber>{stat.value}</StatNumber>
        </Stat>
      ))}
    </SimpleGrid>
  );
}

export default StatsContainer;
import './App.css';
import { useRef, useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getLength, getAmount, getData } from './lib/libhoedometer';
import {
  Box,
  Text,
  Input,
  Stack,
  Button,
  IconButton,
  SimpleGrid,

  FormLabel,
  FormControl,
  FormHelperText,
  FormErrorMessage,

  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,

  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,

  useDisclosure,
  Center,
  Flex

} from '@chakra-ui/react';

import { LinkIcon } from '@chakra-ui/icons';

import convert from 'convert';

import useSound from 'use-sound';
import usePersistForm from './hooks/usePersistForm';

import coinSfx from './sounds/coins-497.mp3';
import newYearSfx from './sounds/and-a-happy-new-year-sms-513.mp3'
import StatsContainer from './components/StatsContainer';

import { useSearchParams } from './hooks/useSearchParams';

import { buildQueryString } from './lib/querystring';

function App() {
  const params = useSearchParams();

  const { t } = useTranslation('common');

  const calcRef = useRef(null);

  const [ playCoin ] = useSound(coinSfx);
  const [ playNewYear ] = useSound(newYearSfx);

  const { isOpen, onToggle } = useDisclosure();

  const getSavedData = useCallback(() => {
    let data = localStorage.getItem('lastHoe');

    if (data) {
      try {
        data = JSON.parse(data);

      } catch (err) {
        console.log(err);
      }

      return {
        name: params.get('name')            ?? data.name,
        bodyCount: params.get('bodyCount ') ?? data.bodyCount,
        asize: params.get('asize')          ?? data.asize,
        tps: params.get('tps')              ?? data.tps,
        spp: params.get('spp')              ?? data.spp
      };
    }

    return {
      name: params.get('name')           ?? '',
      bodyCount: params.get('bodyCount') ?? 1,
      asize: params.get('asize')         ?? 5.3,
      tps: params.get('tps')             ?? 260,
      spp: params.get('spp')             ?? 1
    };

  }, [params]);
  
  const { register, handleSubmit, getValues, formState: { errors } } = useForm({
    defaultValues: getSavedData()
  });

  usePersistForm({ value: getValues(), localStorageKey: 'lastHoe' });
  
  const [ loading, setLoading ] = useState(false);
  const [ result, setResult ] = useState({
    inches: 0,
    feet: 0,
    meters: 0,
    yards: 0,
    kilometers: 0,
    miles: 0,

    // CUM STATS
    ejacTableSpoon: 0,
    ejacOunce: 0,
    ejacLiter: 0,
    ejacPint: 0,
    ejacQuart: 0,
    ejacGallon: 0,

    // DATA STATS
    dataGb: 0,
    dataTb: 0,
    dataPb: 0
  });

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'c') {
      calcRef.current.click();
    }

  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };

  }, [handleKeyPress]);

  const playSound = () => {
    const current = new Date();
    const currentStr = current.toDateString();
    const christmasDay = new Date(`12/24/${current.getFullYear()}`).toDateString();;
    const christmasEve = new Date(`12/25/${current.getFullYear()}`).toDateString();;

    if ((currentStr === christmasDay) || 
        (currentStr === christmasEve)) {

      playNewYear();

    } else {
      playCoin();
    }
  };

  const onSubmit = async(data) => {
    setLoading(true);

    const { bodyCount, asize, tps, spp } = data;

    const leng = getLength( bodyCount, asize, tps, spp);
    const ejac = getAmount(bodyCount, spp);
    const edat = getData(bodyCount, spp);

    setResult({
      // DICK STATS
      inches: leng.toFixed(2),
      feet: convert(leng, 'in').to('feet').toFixed(2),
      meters: convert(leng, 'in').to('meters').toFixed(2),
      yards: convert(leng, 'in').to('yards').toFixed(2),
      kilometers: convert(leng, 'in').to('kilometers').toFixed(2),
      miles: convert(leng, 'in').to('miles').toFixed(2),

      // CUM STATS
      ejacTableSpoon: convert(ejac, 'ml').to('tablespoon').toFixed(2),
      ejacOunce: convert(ejac, 'ml').to('US fluid ounce').toFixed(2),
      ejacLiter: convert(ejac, 'ml').to('liter').toFixed(2),
      ejacPint: convert(ejac, 'ml').to('pint').toFixed(2),
      ejacQuart: convert(ejac, 'ml').to('quart').toFixed(2),
      ejacGallon: convert(ejac, 'ml').to('gallon').toFixed(2),

      dataGb: edat.toFixed(2),
      dataTb: convert(edat, 'gigabyte').to('terabytes').toFixed(2),
      dataPb: convert(edat, 'gigabyte').to('petabytes').toFixed(2)
    });

    setLoading(false);
    playSound();

    if (!isOpen) {
      onToggle();
    }
  };
  
  return (
    <>
      <center style={{ padding: 25 }}>
        <Text fontSize='4xl' alignSelf='center' mb='5' fontFamily='DM7R'>{t('app.title')}</Text>
      </center>

      <Center paddingLeft={5} paddingRight={5}>
        <SimpleGrid 
          className='full' 
          spacing={{ base: 10, sm: 10, md: 10, lg: 24 }} 
          columns={{ base: 1, sm: 1, md: 1, lg: 2 }}>

          <div>
            <form onSubmit={handleSubmit(onSubmit)} data-netlify='true' netlify='true'>
              <Stack spacing={4}>
                <FormControl isInvalid={errors.name}>
                  <FormLabel htmlFor='name'>{t('app.name')}</FormLabel>

                  <Input
                    id='name'
                    type='string' 
                    name='name'
                    placeholder={t('app.name')}
                    errorBorderColor='crimson'
                    {...register('name', { required: `${t('app.name')} ${t('app.name-exception')}` })} />

                  <FormErrorMessage>
                    {errors.name && errors.name.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.bodyCount}>
                  <FormLabel htmlFor='bodyCount'>{t('app.bodyCount')}</FormLabel>

                  <NumberInput step={1}>
                    <NumberInputField
                      id='bodyCount'
                      type='number' 
                      name='bodyCount'
                      placeholder={t('app.bodyCount')}
                      errorBorderColor='crimson'
                      {...register('bodyCount', { required: `${t('app.bodyCount')} ${t('app.number-exception')}`, min: 1 })} />

                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>

                  <FormHelperText>
                    {`${t('app.averageBodyCountDescription')}`} (source: <a target='_blank' rel='noreferrer' href='https://www.cdc.gov/nchs/nsfg/key_statistics/n-keystat.htm'>{t('app.averageBodyCountLinkTitle')})</a>
                  </FormHelperText>

                  <FormErrorMessage>
                    {errors.bodyCount && errors.bodyCount.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.asize}>
                  <FormLabel htmlFor='asize'>{t('app.averageSize')}</FormLabel>

                  <NumberInput step={0.1}>
                    <NumberInputField 
                      id='asize'
                      step='0.1'
                      type='number' 
                      name='averageSize'
                      placeholder={t('app.averageSize')}
                      errorBorderColor='crimson'
                      {...register('asize', { required: `${t('app.averageSize')} ${t('app.number-exception')}`, max: 15, min: 1, maxLength: 3 })} />

                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>

                  <FormHelperText>
                    {`${t('app.averageSizeDescription')}`} (source: <a target='_blank' rel='noreferrer' href='https://pubmed.ncbi.nlm.nih.gov/32666897/'>{t('app.averageSizeLinkTitle')})</a>
                  </FormHelperText>

                  <FormErrorMessage>
                    {errors.asize && errors.asize.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.tps}>
                  <FormLabel htmlFor='tps'>{t('app.tps')}</FormLabel>

                  <NumberInput step={1}>
                    <NumberInputField
                      id='tps'
                      type='number' 
                      name='thrustsPerSession'
                      errorBorderColor='crimson'
                      placeholder={t('app.tps')}
                      {...register('tps', { required: `${t('app.tps')} ${t('app.number-exception')}`, min: 1 })} />

                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>

                  <FormHelperText>
                    {`${t('app.tpsDescription')}`} (source: <a target='_blank' rel='noreferrer' href='https://melmagazine.com/en-us/story/whats-the-average-number-of-thrusts-during-the-average-bout-of-sex'>{t('app.tpsLinkTitle')}</a>)
                  </FormHelperText>

                  <FormErrorMessage>
                    {errors.tps && errors.tps.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.spp}>
                  <FormLabel htmlFor='spp'>{t('app.spp')}</FormLabel>

                  <NumberInput step={1}>
                    <NumberInputField 
                      id='spp'
                      type='number' 
                      name='sessionsPerPartner'
                      errorBorderColor='crimson'
                      placeholder={t('app.spp')}
                      {...register('spp', { required: `${t('app.spp')} ${t('app.number-exception')}`, min: 1 })} />

                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>

                  <FormErrorMessage>
                    {errors.spp && errors.spp.message}
                  </FormErrorMessage>
                </FormControl>

                <Flex>
                  <Button 
                    flex={1}
                    marginRight={3}
                    type='submit' 
                    ref={calcRef} 
                    isLoading={loading} 
                    loadingText={t('app.loading')}>

                    {t('app.submit')}
                  </Button>

                  <IconButton 
                    icon={<LinkIcon />}
                    onClick={() => {
                      const builtQs = buildQueryString(`https://hoedometer.com/`, getValues());
                      
                      navigator.clipboard.writeText(builtQs);

                    }} />

                </Flex>
              </Stack>
            </form>
          </div>
          
          <Accordion allowMultiple defaultIndex={[0]}>
            <AccordionItem border={'none'}>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  {t('app.milesStats')}
                </Box>

                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel>
                <StatsContainer 
                  stats={[
                    { name: t('app.inches'),     value: new Intl.NumberFormat().format(result.inches) },
                    { name: t('app.feet'),       value: new Intl.NumberFormat().format(result.feet) },
                    { name: t('app.meters'),     value: new Intl.NumberFormat().format(result.meters) },
                    { name: t('app.yards'),      value: new Intl.NumberFormat().format(result.yards) },
                    { name: t('app.kilometers'), value: new Intl.NumberFormat().format(result.kilometers) },
                    { name: t('app.miles'),      value: new Intl.NumberFormat().format(result.miles) }
                  ]} />

              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem border={'none'}>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  {t('app.cumStats')}
                </Box>

                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel>
                <StatsContainer 
                  stats={[
                    { name: t('app.tablespoon'), value: new Intl.NumberFormat().format(result.ejacTableSpoon) },
                    { name: t('app.ounce'),      value: new Intl.NumberFormat().format(result.ejacOunce) },
                    { name: t('app.liter'),      value: new Intl.NumberFormat().format(result.ejacLiter) },
                    { name: t('app.pint'),       value: new Intl.NumberFormat().format(result.ejacPint) },
                    { name: t('app.quart'),      value: new Intl.NumberFormat().format(result.ejacQuart) },
                    { name: t('app.gallon'),     value: new Intl.NumberFormat().format(result.ejacGallon) }
                  ]} />

              </AccordionPanel>
            </AccordionItem>

            <AccordionItem border={'none'}>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  {t('app.dataStats')}
                </Box>

                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel>
                <StatsContainer 
                  stats={[
                    { name: t('app.gigabyte'),   value: new Intl.NumberFormat().format(result.dataGb) },
                    { name: t('app.terrabyte'),  value: new Intl.NumberFormat().format(result.dataTb) },
                    { name: t('app.petabyte'),   value: new Intl.NumberFormat().format(result.dataPb) },
                  ]} />

              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </SimpleGrid>
      </Center>
    </>
  );
}

export default App;

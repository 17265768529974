export const buildQueryString = (root, params) => {
  let idx = 0;
  let copy = root;
  
  for (const [key, value] of Object.entries(params)) {
    if ((key === '') || (value === '')) continue;

    if (idx === 0) {
      copy += `?${key}=${value}`;

    } else {
      copy += `&${key}=${value}`;
    }

    idx++;
  }

  return copy;
};
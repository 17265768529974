import { useEffect } from 'react';

const usePersistForm = ({
  value,
  localStorageKey,
}) => {
  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(value));
    
  }, [value, localStorageKey]);

  return;
};

export default usePersistForm;